<template>
  <Layout ref="main">
    <!-- <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-12">
        <div class="card my-3 p-3 ">
            <div class="form-group mb-3">
              <label class="form-check-label mb-2 font-size-14 fw-normal text-muted"> <i class="mdi mdi-bank text-primary me-1 font-size-16"></i> {{$t('siteLang.BankName')}} </label>
              <div class="row mb-3 px-1" v-if="selectedBank">
                <div class="col-6 col-md-4 px-0 bankRadio position-relative" v-for="(value, index) in Bank_name" :key="index" v-show="selectedBank == value.value">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="bankName" :id="index" :value="value.value" v-model="selectedBank">
                    <label class="form-check-label rounded" :for="index">
                      <img :src="require('@/assets/images/app/bank/' + value.img)" class="img-fluid" >
                      <!-- <div class="d-block text-center font-size-11">{{value.label}}</div> -->
                    </label>
                  </div>
                </div>
              </div>
              <input type="text" class="form-control" disabled :value="returnBankName(profileInfo.bankName)"
              :placeholder="$t('siteLang.BankName')">
            </div>
            <div class="form-group mb-3">
              <label class="form-check-label mb-2 font-size-14 fw-normal text-muted">
              <i class="mdi mdi-credit-card text-primary me-1 font-size-16"></i> {{$t('siteLang.AccountNumber')}}  </label>
              <input type="text" class="form-control" v-model="profileInfo.accountNumber" disabled :placeholder="$t('siteLang.AccountNumber')">
            </div>
            <div class="form-group">
              <label class="form-check-label mb-2 font-size-14 fw-normal text-muted">
              <i class="mdi mdi-account text-primary me-1 font-size-16"></i> {{$t('siteLang.AccountName')}}  </label>
              <input type="text" class="form-control" v-model="profileInfo.acountName" disabled
              :placeholder="$t('siteLang.AccountName')">

            </div>
        </div>
      </div>
    </div>
    <Lottie :path="'../animate/nodata.json'" :title="$t('siteLang.BankCardInformation')" :info="'No Data Available'" :show="showLottie"  :timer="0" ref="lottieFunc" />
    <Common ref="commonFunc"/>
  </Layout>
</template>
<script>
import Layout from "@/views/layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
/**
 * Starter page
 */
export default {
  components: { 
    Layout, 
    //PageHeader, 
    Lottie,
    Common
  },
  page() {
    return {
      title: this.$t('siteLang.BankCardInformation'),
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      loading:false,
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      profileInfo:"",
      showLottie:false,
      title: '',
      items: [
        {
          text: appConfig.title,
          to: "/",
        },
        {
          text: "",
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
      Bank_name:[
        {
          "label": "Bank Rakyat Indonesia (BRI)",
          "value": "bri",
          "img": "bri.png"
        },
        {
          "label": "Bank Negara Indonesia (BNI)",
          "value": "bni",
          "img": "bni.png"
        },
        {
          "label": "Permata Bank",
          "value": "permata",
          "img": "permata.png"
        },
        {
          "label": "CIMB Niaga",
          "value": "cimb_niaga",
          "img": "cimb_niaga.png"
        },
        {
          "label": "Bank Mandiri",
          "value": "mandiri",
          "img": "mandiri.png"
        },
        {
          "label": "Bank Jatim",
          "value": "jatim",
          "img": "jatim.png"
        },
        {
          "label": "Bank Syariah Indonesia (BSI)",
          "value": "bsi",
          "img": "bsi.png"
        },
        {
          "label": "Bank Central Asia (BCA)",
          "value": "bca",
          "img": "bca.png"
        },
        {
          "label": "Bank Muamalat Indonesia",
          "value": "muamalat",
          "img": "muamalat.png"
        },
        {
          "label": "Bank Tabungan Negara (BTN)",
          "value": "btn",
          "img": "btn.png"
        },
        {
          "label": "Bank Danamon",
          "value": "danamon",
          "img": "danamon.png"
        },
        {
          "label": "Bank Mega",
          "value": "mega",
          "img": "mega.png"
        }
      ],
      selectedBank:"",
     
    };
  },
  middleware: "authentication",
  mounted(){
    this.$refs.main.changeVerticalTopBar("my profile",true)
    this.$refs.main.setPageTitle(this.$t('siteLang.BankCardInformation'))
    this.title = this.$t('siteLang.MyProfile')
    this.items[1].text = this.$t('siteLang.MyProfile') 
    this.items[1].to = "/my-profile"
    this.items[2].text = this.$t('siteLang.BankCardInformation') 
  
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')
    this.profileInfo = this.$refs.commonFunc.getProfileInfo()
    this.selectedBank = this.profileInfo.bankName
     
  },
  created(){
   
  },
  methods:{
    returnBankName(value){
      const BankName = this.Bank_name.find(bank => bank.value === value);
      if (BankName) {
        return BankName.label;
      }
    },
    getData(){
        this.$Progress.start();
        this.showLottie = true
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("phone",  this.accessPhone);
        axios({
            method: "post",
            url: appConfig.DemoAPI,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
            } 
            else if (resData.status == 401){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                this.$refs.commonFunc.clearData()
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    }
  }
};
</script>
<style scoped>
.bankRadio .form-check{
  padding: 2px 4px;
  margin-bottom: 2px;
}
.bankRadio .form-check-input[type="radio"]{
  position: absolute;
  left: -9999px;
}
.bankRadio .form-check-input[type="radio"] + .form-check-label {
  border: 2px solid #ded4da;
  border-radius: 2px;
}
/* Show border around label when selected */
.bankRadio .form-check-input[type="radio"]:checked + .form-check-label {
  border: 2px solid#04da8c;
}
</style>